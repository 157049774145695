.root {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  background-color: var(--shape-10);
  padding: 12px 12px 8px 8px;
  margin-bottom: 24px;
}

.details {
  position: relative;
  width: 162px;
  margin-right: 114px;
}

.matchNamesWrapper {
  display: grid;
  grid-template-columns: 1fr 8px 8px;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 7px 5px;

  & span {
    background-color: var(--shape-40);
    border-radius: 2px;
  }
}

.matchInfoWrapper {
  display: grid;
  grid-template-columns: 13px 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2px 6px;

  & span {
    background-color: var(--shape-40);
    grid-row-start: 2;
  }
}

.rightCellWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 6px;
  align-items: center;

  & span {
    background-color: var(--shape-20);

    &:not(:last-child) {
      box-shadow: 0 0 0 1px var(--shape-40);
    }
  }
}

.icon {
  color: var(--system-warning);
  position: absolute;
  padding: 6px;
  border: 1px solid var(--shape-50);
  border-radius: 50%;
  bottom: -7px;
  left: 110px;

  &::before {
    content: '';
    position: absolute;
    width: 46px;
    height: 46px;
    border: 1px solid var(--shape-50);
    border-radius: 50%;
    bottom: -10px;
    left: -10px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 66px;
    height: 66px;
    border: 1px solid var(--shape-50);
    border-radius: 50%;
    bottom: -20px;
    left: -20px;
  }
}
